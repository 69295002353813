$platform-name: 'suzylamplughtrust-refresh2019';
$assets-path: '../assets/';

// Their 'Diss' font isn't freely available, nor do they have it (let alone a license)
// So I'm using 'Cabin' - a free alternative that looks incredibly similar
@font-face {
  font-family: 'Cabin';
  src:  url($assets-path + 'Cabin-Regular.woff2') format('woff2'),
        url($assets-path + 'Cabin-Regular.woff') format('woff');
  font-weight: 400
}

@font-face {
  font-family: 'Cabin';
  src:  url($assets-path + 'Cabin-SemiBold.woff2') format('woff2'),
        url($assets-path + 'Cabin-SemiBold.woff') format('woff');
  font-weight: 600
}

@font-face {
  font-family: 'Cabin';
  src:  url($assets-path + 'Cabin-Bold.woff2') format('woff2'),
        url($assets-path + 'Cabin-Bold.woff') format('woff');
  font-weight: 700
}

// For bold headings, use 'Alpha Echo'
// which is similar to the 'LIVE LIFE SAFE' part of their logo

@font-face {
  font-family: 'Alpha Echo';
  src:  url($assets-path + 'alpha_echo.woff2') format('woff2'),
        url($assets-path + 'alpha_echo.woff') format('woff');
  font-weight: 700;
}

$_brand-blue: #173889;
$_brand-purple: #6E288D;
$_brand-green: #008751;
$_national-stalking-helpline-blue: #0194C0;

$brand-primary: $_brand-blue;
$brand-secondary: $_brand-purple;

$grey-dark: #222;
$grey-lighter: #eceeef;
$grey-lightest: #f7f7f9;

$container-max-width: 1400px;
$gap-width: 40px;

$spacer: 1.35rem;

$logo: "logo3.svg";
$logo-width: 290px;
$logo-height: 68px;
$logo-absolute: false;

$font-family-base: 'Cabin', sans-serif;

$headings-font-family: 'Alpha Echo', 'Cabin', sans-serif;
$headings-colour: $brand-primary;
$headings-text-transform: uppercase;

$btn-box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
$btn-padding-x: 1.7em;
$btn-padding-y: .75em;
$btn-font-weight: 700;

$social-icons-background-colour: transparent;
$social-icons-colour: $brand-primary;
$social-icons-box-shadow: none;
$social-icons-height: 44px; // Match button height

$menu-admin-enabled: false;

// .adminBar,
// .adminBarEdit {
//   display: none !important;
// }

// $header-absolute: true;
// $header-absolute-breakpoint: 1024px;
// $header-absolute-background: linear-gradient(
//   to bottom,
//   rgba(#000, 0.7),
//   rgba(#000, 0.4) 60%,
//   rgba(#000, 0.2), 85%,
//   rgba(#000, 0)
// );
// $header-absolute-background: rgba(#fff, .5);
$header-background-colour: #fff;

$header-content-max-width: calc(100% - 40px);
$header-content-margin-top: 0px;
$header-content-margin-bottom: 0px;
$header-content-padding-left: 0;
$header-content-padding-right: 0;

$tagline-enabled: true;
$tagline-font-family: $font-family-base;
$tagline-font-weight: 700;
$tagline-colour: $_national-stalking-helpline-blue;
$tagline-breakpoint: 560px;
$tagline-above-cta-breakpoint: 700px;

//$header-search-enabled: false;
$header-search-input-max-width: 150px;
$header-search-input-margin-right: 0;
$header-search-input-height: 40px;
$header-search-breakpoint: 1100px;

$nav-breakpoint: 1100px;

$nav-background-colour: rgba($brand-primary, .65);

$nav-top-level-item-background-colour: transparent;
$nav-top-level-item-font-weight: 600;
$nav-top-level-item-padding: 10px 16px;
$nav-top-level-item-colour: #fff;
$nav-top-level-item-hover-background-colour: $brand-primary;
$nav-top-level-item-hover-colour: #fff;

$nav-submenu-background-colour: $nav-top-level-item-hover-background-colour;
$nav-submenu-item-colour: #fff;
$nav-submenu-item-font-weight: $nav-top-level-item-font-weight;
$nav-submenu-box-shadow: $btn-box-shadow;
$nav-submenu-item-padding: 10px 16px;

$nav-normal-align-items: right;
$nav-normal-max-width: 100%;
$nav-normal-contents-max-width: calc(100% - 25px);
$nav-normal-margin-bottom: 0;

$carousel-max-width: 100%;

// $no-banner-header-background-colour: #fff;
// $no-banner-tagline-colour: #0194C0;
// $no-banner-social-icons-header-colour: $brand-primary;
// $no-banner-nav-top-level-item-colour: $brand-primary;

$carousel-max-width: 100%;

$carousel-details-background-colour: rgba($_brand-green,.7);
// $carousel-contents-max-width: $container-max-width;
$carousel-details-padding: 2rem 2rem 2rem 5%;
$carousel-details-max-width: 600px;
$carousel-details-position-y: bottom;
$carousel-details-margin-y: 6vw;
$carousel-details-position-x: left;
$carousel-heading-font-size: 2.5em;
$carousel-heading-colour: #fff;
$carousel-summary-font-size: 1.1em;

$carousel-read-more-background-colour: #fff;
$carousel-read-more-colour: text-contrast($carousel-read-more-background-colour);
$carousel-read-more-font-size: .85rem;

$carousel-controls-button-background-colour: $_brand-green;
$carousel-controls-button-size: 50px;
$carousel-controls-button-gap: 5px;
$carousel-controls-margin-y: 40px;
$carousel-controls-margin-x: 20px;

$carousel-dots-padding: 40px;
$carousel-dots-active-colour: $_brand-green;

// $carousel-mobile-details-background-colour: $brand-primary;

$home-intro-background-colour: $_brand-blue;
$home-intro-max-width: 100%;
$home-intro-margin-top: 0;
$home-intro-padding-y: 3rem;

$home-intro-content-max-width: 740px;
$home-intro-font-size: 2em;

$home-features-background-colour: transparent;
$home-features-padding-top: 9rem; // Clear the background shape
$home-features-padding-bottom: 4rem;
$home-features-cards-entry-animation-prefab: 1;

$impact-stats-padding-y: 3rem;
$impact-stats-background-colour: $_brand-purple;
$impact-stats-heading-margin-bottom: 30px;
// $impact-stats-figure-colour: #fff;
$impact-stats-figure-font-size: 3em;

$home-quick-giving-padding-y: 3rem;
$home-quick-giving-background-colour: $_brand-green;
$home-quick-giving-donation-amount-background-colour: transparent;
$home-quick-giving-donation-amount-figure-colour: #fff;
$home-quick-giving-donation-amount-border-width: 4px;
$home-quick-giving-donation-amount-border-colour: #fff;
$home-quick-giving-heading-colour: #fff;
$home-quick-giving-donate-btn-background-colour: darken($home-quick-giving-background-colour, 10%);

$home-feeds-background-colour: transparent;
$home-feeds-padding-top: $home-features-padding-top;
$home-feeds-padding-bottom: $home-features-padding-bottom;
$home-feeds-cards-entry-animation-prefab: 1;

// $card-box-shadow: 0 .25rem .75rem rgba(0,0,0,.1);
$card-border: 0;

$event-card-floating-date: true;
$event-card-floating-date-background-colour: $_brand-green;

$post-content-max-width: 1230px;

$listing-content-max-width: $post-content-max-width;

$footer-background-colour: $_brand-purple;
$footer-link-colour: #fff;

$newsletter-background-colour: #fff;
$newsletter-input-background-colour: $grey-lightest;

$social-icons-footer-background-colour: #fff;
$social-icons-footer-colour: 'brand';
$social-icons-footer-hover-colour: $footer-background-colour;

$blockquote-box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
$blockquote-text-align: left;

$sidebar-enabled: false; // Sidebars will always exist on event and appeal posts
//$sidebar-mobile-layout: below;