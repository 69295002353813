@media (min-width: $nav-breakpoint + 1px) {
  // Circle donate button in header
  .mainCallToAction .cta-button.donate {
    width: 75px; // Match helpline circle
    height: 75px;
    border-radius: 100%;
    padding: 0; // https://www.bugherd.com/projects/176220/tasks/9
  }

  .page-has-banner .menuMain {
    z-index: 1;
    margin-bottom: -44px; // Slide banner up
    position: relative;
  }
}

@media (max-width: $nav-breakpoint) {
  .pageHeader .headerContent {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

// Better layout for tagline
@media (min-width: $tagline-breakpoint + 1px) {
  .pageHeader .headerContent .mainCallToAction {
    flex-grow: 0;
  }
}

.tagline {
  display: flex;
  align-items: center;
  margin: 0 20px !important; // I know best
  justify-content: flex-end;

  img {
    width: 75px;
  }

  @media (max-width: $header-absolute-breakpoint) {
    color: #0194c0;
  }

  @media (max-width: $tagline-breakpoint) {
    display: none;
  }
}

.tagline-copy {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  font-size: 0.85em;
  line-height: 1.1;

  span {
    font-weight: $font-weight-normal;
    font-size: $font-size-small;
  }
}

.homeIntro .button {
  box-shadow: none;
  font-size: 1rem;
  background-color: transparent;
  border: 3px solid $white;
  text-transform: none;
  font-family: $font-family-base;

  &:hover {
    background-color: $white;
    color: $brand-primary;
  }
}

// .quickGivingPanel
//   .formQuestion.donationAmount.donationSelected
//   .donationAmountFigure {
//   box-shadow: inset 0 0 0 4px $donate-colour;
// }

.homefeaturecategory-homeboxquickgiving .quickGivingPanel .donateBtn {
  height: 60px; // Match the row
}

// Quick exit button
#quick-exit-button {
  // display: none; // Hide this for now
  position: fixed;
  top: 50%;
  right: 0;
  margin: 0;
  transform: translateY(-50%) rotate(-90deg);
  transform-origin: bottom right;

  &:after {
    content: '\f08e';
    display: inline-block;
    margin-left: 0.5em;
    font-weight: normal;
    font-family: 'FontAwesome';
  }

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    transform: none;
  }
}

//
// TRIANGLES
//

// Home cards triangles
.homeFeatures .homeFeature {
  .homeFeatureDetailsWrapper {
    position: relative;
    &:before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 7px 20px 7px;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: -20px;
      transition: all $transition-duration $transition-curve;
    }
  }
  // &:hover .homeFeatureDetailsWrapper:before {
  //   border-color: transparent transparent #fff transparent;
  // }

  &:hover .homeFeatureDetailsWrapper {
    transform: translateY(-20px);
  }
}

.homeBox1 .homeFeatureDetailsWrapper:before {
  border-color: transparent transparent $_brand-purple transparent;
}

.homeBox2 .homeFeatureDetailsWrapper:before {
  border-color: transparent transparent $_brand-blue transparent;
}

.homeBox3 .homeFeatureDetailsWrapper:before {
  border-color: transparent transparent $_brand-green transparent;
}

// Blockquote triangle
blockquote {
  position: relative;
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 14px 0 0;
    border-color: $blockquote-background-colour transparent transparent
      transparent;
    position: absolute;
    left: $blockquote-padding;
    bottom: -20px;
  }
}

// Ragged edges
@mixin ragged-straight-top($colour) {
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 20px;
    position: absolute;
    top: -18px;
    left: 0;
    right: 0;
    pointer-events: none;
    background-size: 102% 102%;
    background-repeat: no-repeat;
    background-position: center top;
    background-image: url($assets-path+'ragged-straight-top--#{$colour}.png');
  }
}

@mixin ragged-bottom($colour, $height: 9vw) {
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: $height;
    position: absolute;
    bottom: calc(#{$height * -1} + 2px);
    left: 0;
    right: 0;
    pointer-events: none;
    background-size: 102% 102%;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-image: url($assets-path+'ragged-bottom--#{$colour}.png');
    z-index: -1;
  }
}

.homeIntroWrapper {
  @include ragged-straight-top('blue');
  @include ragged-bottom('blue');
  padding-bottom: $home-intro-padding-y / 2;
}

.homefeaturecategory-homeboximpactstats {
  @include ragged-straight-top('purple');
}

.homefeaturecategory-homeboxquickgiving {
  @include ragged-straight-top('green');
  @include ragged-bottom('green');
  padding-bottom: 2rem;
}

// Ragged underline
@mixin ragged-underline($colour, $width, $height: $width * 0.03, $align: left) {
  &:after {
    content: '';
    position: relative;
    width: $width;
    height: $height;
    min-height: 10px;
    background-image: url($assets-path+'ragged-underline--#{$colour}.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: block;
    pointer-events: none;
    margin-top: $headings-margin-bottom / 2;

    @if $align == center {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.carouselSlideHeading {
  @include ragged-underline($colour: 'white', $width: 200px);
}

.homeImpactWrapper > h2 {
  @include ragged-underline($colour: 'white', $width: 200px, $align: center);
}

.homeFeedBox1 .feedsTitle {
  @include ragged-underline($colour: 'blue', $width: 160px);
}

.homeFeedBox2 .feedsTitle {
  @include ragged-underline($colour: 'green', $width: 160px);
}

.footerBox.NewsletterSign-up {
  > h1 {
    @include ragged-underline($colour: 'blue', $width: 200px, $align: center);
  }

  input {
    border: 1px solid $border-colour;
  }
}

// Cards
.feedItem footer,
.listedPost footer {
  width: 100%;
  max-width: calc(100% - 40px) !important;
  margin: 0 auto;
}

#{$card-details} {
  width: 100%;
  max-width: calc(100% - 40px) !important;
  margin: -20px auto 0;
  z-index: 1;
}

// Footer
.footer-helpline {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  a {
    text-decoration: none !important;
    font-weight: 700;
    margin-left: 10px;
    font-size: 1.2em;
  }
}

.footerBox .socialIcons {
  margin-bottom: 20px;
}

// Announcement Alert
// This (https://suzylamplughtrust-refresh2019.eu.rit.org.uk/Manager/Snippet.aspx?idPost=90deab6f-1905-43a9-80da-40bf69c720be) snippet can be used to create an announcement banner at the top of the page below the main nav
.footerBox.AnnouncementAlert {
  display: none; // Hide this for now
  text-align: center;
  width: 100%;
  @include parentless-container-wrapper($container-max-width);
  margin: 20px auto;
  padding: 1rem;
  font-weight: bold;
  background-color: rgba($brand-primary, 0.1);
  border: 1px solid $brand-primary;
  color: $brand-primary;

  a {
    text-decoration: underline;
  }
}

// Indent page with banner post content
.page-has-banner[class*='PostBody'] {
  .headerText {
    padding-top: calc(#{$header-text-margin-top} - 40px);
    position: relative;
  }

  .headerText:before {
    content: '';
    width: 100%;
    max-width: $post-content-max-width + 40px;
    height: 40px;
    margin: 0 auto;
    position: absolute;
    top: -40px;
    left: 0;
    right: 0;
    background-color: #fff;
  }
}

// Stat icons
.homefeaturecategory-homeboximpactstats {
  td:first-child {
    &:before {
      content: '';
      width: 100%;
      height: 120px;
      display: block;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      margin: 0 auto 20px;
    }
  }

  tr:nth-child(1) td:first-child:before {
    background-image: url($assets-path+'stat-icon--phone.png');
  }

  tr:nth-child(2) td:first-child:before {
    background-image: url($assets-path+'stat-icon--shield.png');
  }

  tr:nth-child(3) td:first-child:before {
    background-image: url($assets-path+'stat-icon--grad.png');
  }
}


// Page - Logo slider
body.PostCategory_page-logo-slider {
  .postAside {
    display: none;
  }
  .logoContainer {
    display: block;
    width: 100vw;
    margin: 20px 0;
    max-width: 100vw;
    position: relative;
    left: calc((100vw - (#{$post-content-max-width} - #{$site-bleed * 2})) / -2);
    @media (max-width: $post-content-max-width) {
      left: -$site-bleed;
    }
    .slick-list{
      overflow: hidden;
    }
    .slick-track {  
      display: flex;
      align-items: center;
      justify-content: center;
      >  * {
        margin: $spacer;
      }
    }

    @media (min-width: map-get($breakpoints, sm) + 1px) {
      img { opacity: 0.75; }
      a:hover img { opacity: 1; }
    }


    ul.slick-dots {
      padding-inline-start: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        button {
          margin: 0;
          font-size: 0;
          position: relative;
          background-color: transparent;
          padding: 0;
          height: 25px;
          width: 25px;
          box-shadow: none;
          &:hover { 
            background-color: transparent;
            &:before { content: '\f111'; }
          }
          &:before {
            content: '\f10c';
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $icon-font-family;
            font-size: 1.1rem;
            color: $brand-primary;
          }
        }
        &::marker { content: none !important; }
        &.slick-active { 
          background-color: transparent;
          button:before { content: '\f111'; }
        }
      }
    }
  }

}


// Homepage logos
.footerBox.Footer-Logos {
  padding: $spacer;
  max-width: $container-max-width;
  margin: auto;  

  > h1 {
    @include ragged-underline($colour: 'blue', $width: 200px, $align: center);
  }

  img {
    max-width: 180px;
    height: auto;
    object-fit: contain;
    margin: 1.5rem;
  }
}

body:not(.homepage) .footerBox.Footer-Logos {
  display: none;
}
